import React, { useEffect, useState, createContext, useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';

import { useAuth } from 'context/AuthProvider';
import { track } from 'context/AnalyticsProvider';
import useWindow from 'context/useWindow';
import {
	calculateDownPaymentAmount,
	formatPhoneNumber,
	getUrl,
	priceFormatting,
	scrollToElement,
} from 'libs/content';
import { storageNames } from 'components/strombestilling/helpers/strombestillingProvider';

const ProductOrderContext = createContext();
export const useProductOrder = () => useContext(ProductOrderContext);

export const ELEKTRO_PRODUCT_ORDER_KEY = 'nte-elektro-product-order';
export const ELEKTRO_LOGOUT_PRODUCT_ORDER_INFO_KEY =
	'nte-elektro-logout-product-order-info';

export default function ProductOrderProvider({
	location,
	product,
	productLoading,
	productError,
	spotpris,
	departments,
	...rest
}) {
	const { windowWidth } = useWindow();
	const storedOrder = useStoredElektroOrder();
	const storedPowerOrder = useStoredPowerOrder();
	const { user, isAuthenticated, loading: authLoading } = useAuth();
	const [submissionError, setSubmissionError] = useState(false);
	const [error, setError] = useState(productError || false);
	const [isMobile, setIsMobile] = useState(false);
	const [ageError, setAgeError] = useState(false);
	const [discountError, setDiscountError] = useState(false);

	const [orderData, setOrderData] = useState({
		step: 1,
		stepsValidated: [],
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		birthdate: '',
		address: {},
		invoiceAddress: {},
		deliveryMethod: 'Elektrikeren tar med alt som trengs hjem til deg',
		department: null,
		product: {
			quantity: 1,
		},
		paymentMethod: '',
		acceptMarketing: false,
	});

	/**
	 * Update state on form input change
	 * @param {string} name - Name of the input field
	 * @param {string} value - Value of the input field
	 * @returns {void}
	 */
	const updateOrder = (name, value) => {
		setOrderData(prev => ({ ...prev, [name]: value }));
	};

	/**
	 * Update validated steps array
	 * @param {number} step - Step number
	 * @returns {void}
	 */
	function updateValidatedSteps(step) {
		if (orderData?.stepsValidated?.includes(step)) return;
		updateOrder('stepsValidated', [...orderData?.stepsValidated, step]);
	}

	/**
	 * Get department with matching zipcode based on the zipcode that the user entered
	 * @param {string} zip - Zipcode
	 * @param {array} departments - Array of departments
	 * @returns {object} - Department object
	 */
	function findDepartment(zip, departments) {
		if (!departments.length || !zip) return;

		const matches = departments.filter(dep => {
			const zipRanges = dep?.zips?.zipcodes?.split(',');
			return zipRanges.some(zipRange => {
				if (zipRange.includes('-')) {
					const [start, end] = zipRange.split('-').map(Number);
					return zip >= start && zip <= end;
				} else {
					return zip === parseInt(zipRange, 10);
				}
			});
		});

		return matches.length
			? { title: matches[0]?.title, email: matches[0]?.email }
			: {};
	}

	/**
	 * Track checkout steps in Segment
	 * @param {number} step - Step number
	 * @returns {void}
	 */
	function trackStep(step) {
		if (!step) return;

		const productTitle =
			product?.title && product?.smallTitle
				? `${product?.title} (${product.smallTitle})`
				: product?.title;

		if (step !== 1 && orderData?.stepsValidated?.includes(step)) {
			track('Checkout Step Completed', {
				step: step - 1,
				label: productTitle,
				category: 'Produkter',
				markedsomrade: orderData?.department?.name || undefined,
			});
			console.log(
				`Track "Checkout Step Completed: ${step - 1}" to Segment`
			);
		}

		track('Checkout Step Viewed', {
			step,
			name: productTitle,
			label: productTitle,
			product_id: product?.id,
			category: 'Produkter',
			internal_title: product?.smallTitle,
			markedsomrade: orderData?.department?.name || undefined,
			payment_method:
				orderData?.paymentMethod === 'downpayment'
					? 'Avbetaling'
					: 'Faktura',
		});
		console.log(`Track "Checkout Step Viewed: ${step}" to Segment`);
	}

	/**
	 * Clear stored product order data from sessionStorage
	 * @returns {void}
	 */
	function clearStorage() {
		const powerOrderKeys = storageNames;

		// Clear stored product order data
		sessionStorage.removeItem(ELEKTRO_PRODUCT_ORDER_KEY);

		// Clear stored power order data (Strømbestilling)
		sessionStorage.removeItem(powerOrderKeys?.order);
		sessionStorage.removeItem(powerOrderKeys?.user);
		sessionStorage.removeItem(powerOrderKeys?.searchResult);
		localStorage.removeItem(powerOrderKeys?.logoutOrderInfo);
	}

	useEffect(() => {
		// If step is 1 then don't proceed
		if (orderData?.step === 1) return;

		// Scroll to current section on step change
		const currentSection = document.querySelector(
			`[data-step="section-${orderData?.step}"]`
		);
		if (!currentSection) return;
		setTimeout(() => {
			scrollToElement(currentSection, 200);
		}, 750);

		//eslint-disable-next-line
	}, [orderData?.step]);

	// Update isMobile state based on window width
	useEffect(() => {
		setIsMobile(windowWidth <= 1099);
		//eslint-disable-next-line
	}, [windowWidth]);

	// Update user date on authentication
	useEffect(() => {
		if (authLoading) return;

		if (!isAuthenticated && !authLoading) {
			clearStorage();
			setOrderData({ step: 1, stepsValidated: [] });
			return;
		}

		const address = extractAddressComponents(user?.address?.street_address);

		setOrderData(prev => ({
			...prev,
			firstname: prev.firstname || user?.given_name,
			lastname: prev.lastname || user?.family_name,
			email: prev.email || user?.email,
			phone:
				prev.phone ||
				(user?.phone ? formatPhoneNumber(user?.phone) : ''),
			birthdate:
				prev.birthdate ||
				(user?.birthdate &&
					dayjs(user?.birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY')),
			address: {
				full: prev.address?.full || user?.address?.street_address,
				street:
					prev.address?.street ||
					address?.street?.replace(/\n/g, ' '),
				number: prev.address?.number || address?.number,
				zip: prev.address?.zip || user?.address?.postal_code,
				city: prev.address?.city || user?.address?.region,
			},
		}));

		//eslint-disable-next-line
	}, [isAuthenticated, authLoading, user?.given_name]);

	// Update order data on product wuantity, discount code or payment method change
	useEffect(() => {
		if (productLoading || !orderData?.product?.title) return;

		const updatedOrderData = updateOrderData(orderData);
		setOrderData(prev => ({
			...prev,
			...updatedOrderData,
		}));

		//eslint-disable-next-line
	}, [
		orderData?.paymentMethod,
		orderData?.product?.quantity,
		orderData?.discount?.discountCode,
	]);

	// Update order data from sessionStorage on first render
	useEffect(() => {
		if (!storedOrder) return;
		updateStoredElektroOrder(storedOrder, setOrderData);

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (productLoading || !product?.price) return;

		const terms = product?.terms
			? product?.terms?.includes('https://nte.no')
				? product?.terms
				: `https://nte.no${getUrl(product?.terms)}`
			: 'https://nte.no/produkter/kjopsbetingelser/';

		setOrderData(prev => ({
			...prev,
			product: {
				slug: product?.slug,
				title: product?.title,
				internalTitle: product?.smallTitle,
				quantity: 1,
				segment: 'Privat',
				mva: product?.mva === 'Ekskludert' ? 'eks. mva' : 'inkl. mva',
				terms,
				price: product?.price,
				priceCampaign: product?.campaignPrice || undefined,
				revenue: product?.revenue,
			},
			priceTotal: product?.price,
			orderRecipient: product?.adminEmail || undefined,
		}));
		//eslint-disable-next-line
	}, [product?.price, productLoading]);

	return (
		<ProductOrderContext.Provider
			value={{
				location,
				updateValidatedSteps,
				orderData,
				setOrderData,
				product,
				productLoading,
				spotpris,
				error,
				setError,
				updateOrder,
				departments,
				findDepartment,
				calculatePriceTotal,
				isMobile,
				ageError,
				setAgeError,
				discountError,
				setDiscountError,
				trackStep,
				submissionError,
				setSubmissionError,
				storedPowerOrder,
				clearStorage,

				storeBeforeLogout: (order, orderId) => {
					if (!order || !orderId) return;
					localStorage.setItem(
						ELEKTRO_LOGOUT_PRODUCT_ORDER_INFO_KEY,
						JSON.stringify({
							orderId: orderId,
							...order,
						})
					);
				},
			}}
			{...rest}
		/>
	);
}

/**
 * Hook to get stored product order data from sessionStorage
 * @returns {object} - Object with stored product order data
 */
export function useStoredElektroOrder() {
	if (typeof window === 'undefined') return null;
	const storedOrder = sessionStorage?.getItem(ELEKTRO_PRODUCT_ORDER_KEY);
	return storedOrder ? JSON.parse(storedOrder) : null;
}

/**
 * Update stored product order data in sessionStorage
 * @param {object} data - Object with order data
 * @returns {void}
 */
export function updateStoredElektroOrder(data, setData) {
	if (typeof window === 'undefined') return null;
	if (!data) return;

	console.log('Saved order data to sessionStorage');
	sessionStorage.setItem(
		ELEKTRO_PRODUCT_ORDER_KEY,
		JSON.stringify({ ...data })
	);
	if (setData) setData(data);
}

/**
 * Hook to get stored power order (Strømbestilling) data from localStorage
 * @returns {object} - Object with stored power order data
 * @returns {object} - Object with stored power order data
 */
export function useStoredPowerOrder() {
	if (typeof window === 'undefined') return null;
	const storedOrder = localStorage?.getItem(storageNames?.logoutOrderInfo);
	return storedOrder ? JSON.parse(storedOrder) : null;
}

/**
 * Extract street and number from address string
 * @param {string} address - Address string
 * @returns {object} - Object with street and number
 */
export function extractAddressComponents(address) {
	if (!address) return { street: '', number: '' };
	const match = address.match(/^(.+?)\s+(\d+\w*)$/) || [null, address, ''];
	const street = match[1];
	const number = match[2];
	return { street, number };
}

/**
 *  Calculate total price based on quantity and number of downpayment months if that payment method is chosen
 * @param {object} orderData - Object with order data (product, discount, paymentMethod)
 * @param {number} months - Number of downpayment months (default is 36)
 * @returns {object} - Object with total price, discount amount and price with discount
 */
export function calculatePriceTotal(orderData = {}, months = 36) {
	const price =
		orderData?.product?.priceCampaign || orderData?.product?.price;
	const quantity = orderData?.product?.quantity;
	const discount = orderData?.discount || {};

	if (!price || !quantity) return;

	const totalPrice = price * quantity; // Calculate total price based on quantity
	let discountAmount = discount?.discountCalculated || 0; // Set discount amount to 0 by default

	// If discount is set then calculate the discount amount based on the discount type (percentage or fixed amount)
	if (discount?.discount) {
		discountAmount =
			discount.discountType === 'percentage'
				? totalPrice * (parseFloat(discount.discount) / 100)
				: parseFloat(discount.discount);
	}

	// Calculate final price based on payment method
	const finalPrice =
		orderData?.paymentMethod === 'downpayment'
			? calculateDownPaymentAmount(totalPrice, months)
			: totalPrice;

	// Calculate price with discount based on payment method
	const priceWithDiscount =
		orderData?.paymentMethod === 'downpayment'
			? calculateDownPaymentAmount(totalPrice - discountAmount, months)
			: finalPrice - discountAmount;

	return {
		price: totalPrice,
		discountAmount,
		priceWithDiscount,
	};
}

/**
 * Update order data with calculated price and discount
 * @param {object} orderData - Object with order data (product, discount, paymentMethod)
 * @returns {object} - Object with updated order data
 */
function updateOrderData(orderData) {
	const { price, priceWithDiscount, discountAmount } =
		calculatePriceTotal(orderData);

	const pricePerMonth =
		orderData?.paymentMethod === 'downpayment'
			? priceFormatting(priceWithDiscount) + '/mnd.'
			: undefined;

	return {
		priceTotal: price,
		priceTotalWithDiscount: priceWithDiscount,
		pricePerMonth,
		product: { ...orderData?.product },
		discount: discountAmount
			? {
					...orderData?.discount,
					discountCalculated: discountAmount,
			  }
			: undefined,
	};
}
